export const NavigationItems = [
  {
    title: 'Dashboard',
    tags: 'dashboard',
    icon: 'ni ni-home',
    items: [
      {
        title: 'Summary',
        tags: 'summary',
        routerLink: '/main/dashboard/summary'
      },
      {
        title: 'Error List',
        tags: 'error list',
        routerLink: '/main/dashboard/error-list'
      }
    ]
  },
  {
    title: 'Message Management',
    tags: 'message management',
    icon: 'fal fa-align-justify',
    items: [
      {
        title: 'Search Messages',
        tags: 'search messages',
        routerLink: '/main/message-management/search-messages'
      },
      {
        title: 'Schema Type',
        tags: 'schema type',
        routerLink: '/main/message-management/schema-type'
      },
      {
        title: 'Messaging Type',
        tags: 'messaging type',
        routerLink: '/main/message-management/messaging-type'
      }
    ]
  },
  {
    title: 'Listener Management',
    tags: 'listener management',
    icon: 'fal fa-assistive-listening-systems',
    items: [
      {
        title: 'Messaging Definitions',
        tags: 'messaging definitions',
        routerLink: '/main/listener-management/messaging-definition'
      },
      {
        title: 'Send Receive',
        tags: 'send receive',
        routerLink: '/main/listener-management/message-send-receive'
      },
      {
        title: 'Web Service',
        tags: 'webservice reference',
        routerLink: '/main/listener-management/web-service'
      },
      {
        title: 'Services Manager',
        tags: 'services manager',
        routerLink: '/main/listener-management/services-manager'
      }
    ]
  },
  {
    title: 'Teletype Message',
    tags: 'teletype message',
    icon: 'fal fa-file-alt',
    routerLink: '/main/tty-message/inbox'
  }
];
