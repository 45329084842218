import { Injectable } from '@angular/core';

@Injectable()
export class FilterService {

    public filterNumericCondition(conditionString: string, compareValue: number): boolean {

        if (!conditionString) {
            return true;
        }

        const operatorRegex: RegExp = /(>|<|!=|[>=]|[<=]|==)*/;
        let operatorIndexs = conditionString.match(operatorRegex);

        if (operatorIndexs.length > 0 && operatorIndexs[1]) {
            let searchValue = conditionString.replace(operatorRegex, "").trim();
            if (searchValue.length == 0) {
                return false;
            }
            
            return this.compareOperator(operatorIndexs[0], compareValue, Number(searchValue));
        }
        return false;
    }

    private compareOperator(operator: string, compareValue: number, searchValue: number): boolean {
        switch (operator) {
            case ">":
                return compareValue > searchValue;
            case "<":
                return compareValue < searchValue;
            case ">=":
                return compareValue >= searchValue;
            case "<=":
                return compareValue <= searchValue;
            case "!=":
                return compareValue != searchValue;
            case "==":
                return compareValue == searchValue;
            default:
                return false;
        }
    }
    public filterString(conditionString: string, compareValue: string): boolean {
        if (!conditionString) {
            return true;
        }

        return compareValue.toLowerCase().indexOf(conditionString.toLowerCase()) > -1;
    }
}