import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routing } from './dashboard-routing.module';


@NgModule({
    imports: [
        CommonModule,
        routing
    ],
    declarations: [

    ],
    providers: []
})
export class DashboardModule {

}