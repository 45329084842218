import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { mobileNavigation } from 'src/app/store/navigation';
import { APP_CONFIG } from 'src/app/app.config';
import { OrganisationSelectorService } from 'src/app/core/services/organisation-selector.service';
import { OrganisationView } from 'src/app/modules/dashboard/organisation-selector/shared/organisation-view';

@Component({
  selector: 'smart-page-header',
  templateUrl: './page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent implements OnInit, OnDestroy {

  avatar = APP_CONFIG.avatar;
  email = APP_CONFIG.email;
  user = APP_CONFIG.user;

  organisationName: string;

  public orgName: string;
  private org: OrganisationView;

  private organisationSubscription;

  constructor(private store: Store<any>,
    public organisationSelectorService: OrganisationSelectorService,
    private cdf: ChangeDetectorRef) {

  }
  ngOnDestroy(): void {
    this.organisationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.organisationSubscription = this.organisationSelectorService.sharedOrganisation.subscribe(
      message => {
        this.org = message;
        this.orgName = this.org.organisationName;

        this.cdf.markForCheck();
      });
  }

  openMobileNav($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(mobileNavigation({ open: true }));
  }

}
