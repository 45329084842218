export class TtyMessageLogDetailView {
    public id: string;
    public messagePairId: string;
    public messageBody: string;
    public messageWrapper: string;
    public messageTypeCode: string;
    public messageDateTime: string;
    public senderIdentification: string;
    public receiverIdentification: string;
    public messagePriorityCode: string;
    public readDateTime: string;
    public archiveDateTime: string;
    public messageSubTypeCode: string;
    public messagePriorityText: string;
}