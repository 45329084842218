import { Component, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { mobileNavigation } from 'src/app/store/navigation';
import { APP_CONFIG } from 'src/app/app.config';
import { MessageLogService } from 'src/app/core/services/message-logs.service';

@Component({
  selector: 'smart-main',
  templateUrl: './main.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent {

  app = APP_CONFIG.app;
  constructor(private store: Store<any>) { }

  closeMobileNav($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(mobileNavigation({ open: false }));

  }
}
