import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { selectNavigationItems, NavigationItem } from 'src/app/store/navigation';
import { selectSettingsState } from 'src/app/store/settings';

@Component({
  selector: 'smart-nav',
  templateUrl: './nav.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class NavComponent {

  private items = selectNavigationItems;

  vm$ = this.store.select(
    createSelector(
      selectNavigationItems,
      selectSettingsState,
      (items, settings) => ({ items, minified: settings.minifyNavigation })
    )
  );

  constructor(private store: Store<any>, private cdr: ChangeDetectorRef) { }

  trackByFn(idx: number, item: NavigationItem) {
    return item.title + '_' + idx;
  }
  filterMatchItems(items: NavigationItem[]) {
    return items.filter(x => x.matched === null || x.matched == true);
  }
}
