import { HttpClient, HttpParams, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

const TOKEN_NAME: string = "auth_token";


export abstract class ApiServiceBase {

    private _baseUrl: string;
    private _httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this._baseUrl = environment.apiUrl;
        this._httpClient = httpClient;
    }

    protected httpGet<T>(url: string, param: HttpParams, authKey?: string, optHeaders?: HttpHeaders): Observable<HttpResponse<T>> {
        
        let headers: HttpHeaders;
        if (optHeaders) {
            headers = optHeaders;
        } else {
            headers = this.createAuthHttpHeader(authKey);
        }
        return this._httpClient.get<T>(this._baseUrl + url, 
            { headers, observe: 'response' , params: param });
    }

    protected httpPost<T>(url: string, body: any, authKey?: string, optHeaders?: HttpHeaders): Observable<HttpResponse<T>> {
        
        let headers: HttpHeaders;
        if (optHeaders) {
            headers = optHeaders;
        } else {
            headers = this.createAuthHttpHeader(authKey);
        }
        return this._httpClient.post<T>(this._baseUrl + url, body, { headers, observe: 'response' });
    }

    protected httpPut<T>(url: string, body: any, authKey?: string, optHeaders?: HttpHeaders): Observable<HttpResponse<T>> {
        
        let headers: HttpHeaders;
        if (optHeaders) {
            headers = optHeaders;
        } else {
            headers = this.createAuthHttpHeader(authKey);
        }
        return this._httpClient.put<T>(this._baseUrl + url, body, { headers, observe: 'response' });
    }

    protected httpDelete(url: string, authKey?: string, optHeaders?: HttpHeaders): Observable<HttpResponse<any>> {
        
        let headers: HttpHeaders;
        if (optHeaders) {
            headers = optHeaders;
        } else {
            headers = this.createAuthHttpHeader(authKey);
        }
        return this._httpClient.delete(this._baseUrl + url, { headers, observe: 'response' });
    }

    private createAuthHttpHeader(authKey: string, optHeaders?: HttpHeaders): HttpHeaders {
        const AUTH_HEADER: string = 'Authorization';
        const AUTH_TYPE: string = 'Basic';
        if (authKey) {
            return new HttpHeaders().set(AUTH_HEADER, `${AUTH_TYPE} ${authKey}`)
        }
        let token: string = localStorage.getItem(TOKEN_NAME);
        if (!token) {
            return null;
        }
        return new HttpHeaders().set(AUTH_HEADER, `${AUTH_TYPE} ${token}`)
    }
}