import { Injectable} from '@angular/core';
import { OrganisationView } from 'src/app/modules/dashboard/organisation-selector/shared/organisation-view';
import { BehaviorSubject } from 'rxjs';

const ORG_KEY: string = 'organisation_id';
const ORGANISATION: string = 'organisation';

@Injectable({
    providedIn: 'root'
})
export class OrganisationSelectorService {
    private organisationID = new BehaviorSubject('');
    shareOrg = this.organisationID.asObservable();

    private organisation = new BehaviorSubject<OrganisationView>(new OrganisationView);
    sharedOrganisation = this.organisation.asObservable();

    nextOrgId(organisationId: string) {
        this.organisationID.next(organisationId);
    }

    nextOrganisation(organisation: OrganisationView) {
        this.organisation.next(organisation);
    }
}