import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OopsComponentFormBase } from '../../../core/base/oops-component-form-base';
import { AuthService } from '../../../core/authentication/auth.service';
import { APP_CONFIG } from '../../../app.config';

@Component({
    selector: 'gw-login',
    templateUrl: './login-component.html'
})
export class LoginComponent extends OopsComponentFormBase {
    public copyright: string;
    public applicationName: string;
    
    constructor(private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router) { 
        super(formBuilder);
    }
    
    public initForm() {
        this.authService.logout();
        this.renderCopyRight()
        this.applicationName = APP_CONFIG.appName;
        this.formSetup();
    }
    
    private renderCopyRight(): void {
        this.copyright = APP_CONFIG.copyright.replace('{{YEAR}}', ((new Date()).getUTCFullYear()).toString());
    }

    onSubmit(logonData) {
        this.formGroup.reset();
        this.startProcessing();
        this.authService.login(logonData.userName, logonData.password)
            .subscribe(
                (response: Boolean) => {
                    this.router.navigate(['/main']);
                },
                error => {
                    console.log(error);
                    this.completeProcessing();
                });
    }

    private formSetup() {
        let formGroupValue = {
            userName : ['', Validators.required],
            password : ['', Validators.required],
        }
        this.setformGroupValue(formGroupValue);
    }
}