import { Component, OnInit } from '@angular/core';
import { APP_CONFIG } from 'src/app/app.config';

@Component({
  selector: 'smart-page-footer',
  templateUrl: './page-footer.component.html',
  styles: []
})
export class PageFooterComponent implements OnInit {

  public copyright: string;
  constructor() { }

  ngOnInit() {
    this.renderCopyRight();
  }

  private renderCopyRight(): void {
    this.copyright = APP_CONFIG.copyright.replace('{{YEAR}}', ((new Date()).getUTCFullYear()).toString());
  }
}
