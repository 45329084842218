import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { MessageLogOverviewModel } from '../../../core/models/message-log-overview.model'
import { MessageLogService } from '../../../core/services/message-logs.service';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getRouterState } from 'src/app/store/router';
import { ActivatedRoute, RouterStateSnapshot, Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { filter, map, first } from 'rxjs/operators';
import { stringify } from 'querystring';



@Component({
    selector: 'gw-menu-list',
    templateUrl: 'menu-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuListComponent implements OnInit {

    public composeTrig;
    public dataInboxUnreadCount: Subject<number> = new Subject();
    public dataOutboxUnreadCount: Subject<number> = new Subject();
    public tab: number;

    private breadcrumbBase = [{ name: 'TTY message', path: '/main/tty-message/inbox' }];
    private subscriptions: Subscription[];
    private schemaTypeCode = "TTY";

    constructor(
        private messageLogService: MessageLogService,
        private store: Store<any>,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.tabActive(1);
        this.composeTrig = false;
        this.countTtyMessage();
    }

    public countTtyMessage() {
        var now = new Date();
        var previousMonth = new Date();
        previousMonth.setMonth(now.getMonth() - 1)
        let startDateTime = new Date(previousMonth.toUTCString());
        let endDateTime = new Date(now.toUTCString());
        this.messageLogService.getMessageLogOverviews(null, null, null, null,
            startDateTime.toISOString(),
            endDateTime.toISOString(),
            null, null, null,
            this.schemaTypeCode)
            .subscribe(
                (response: MessageLogOverviewModel[]) => {
                    let dataInboxUnread = response.filter(d => d.outgoingFlag == false && (d.readDateTime == null) && d.archiveDateTime == null);
                    this.dataInboxUnreadCount.next(dataInboxUnread.length);
                    let dataOutboxUnread = response.filter(d => d.outgoingFlag == true && (d.readDateTime == null) && d.archiveDateTime == null);
                    this.dataOutboxUnreadCount.next(dataOutboxUnread.length);
                },
                error => {
                    console.log(error);
                }
            );
    };

    public tabActive(tabId) {
        this.tab = tabId;
        this.composeTrig = false;
        this.configBreadCrumb();
    }

    public compose() {
        this.composeTrig = true;
        this.setBreadCrumb('New', '');
    }

    private closeSendMessage($event) {
        this.composeTrig = false;
        this.configBreadCrumb();
    }

    private unreadInbox($event) {
        this.dataInboxUnreadCount.next($event);
    }

    private unreadOutbox($event) {
        this.dataOutboxUnreadCount.next($event);
    }

    private configBreadCrumb() {
        if (this.tab == 1) {
            this.setBreadCrumbMenu('Inbox', '/main/tty-message/inbox');
        }
        else if (this.tab == 2) {
            this.setBreadCrumbMenu('Inbox-Archived', '');
        }
        else if (this.tab == 3) {
            this.setBreadCrumbMenu('Outbox', '');
        }
        else if (this.tab == 4) {
            this.setBreadCrumbMenu('Outbox-Archived', '');
        }
    }

    private setBreadCrumbMenu(name, path) {
        let a = this.store.select(getRouterState);
        a.subscribe(b => {
            if (b.state.data.breadcrumbs[0].name == 'TTY message') {
                let index = b.state.data.breadcrumbs.length - this.breadcrumbBase.length;
                if (index > 0) {
                    b.state.data.breadcrumbs.splice(this.breadcrumbBase.length);
                }
                b.state.data.breadcrumbs.push({ name: name, path: path });
            }
        })
    }

    private setBreadCrumb(name, path) {
        let a = this.store.select(getRouterState);
        a.subscribe(b => {
            if (b.state.data.breadcrumbs[0].name == 'TTY message') {
                b.state.data.breadcrumbs.push({ name: name, path: path });
            }
        })
    }
}
