export class TtyMessageLogView {
    public no: number;
    public id: string;
    public messageTypeCode: string;
    public senderIdentification: string;
    public receiverIdentification: string;
    public providerCode: string;
    public messageStatusCode: string;
    public outgoingFlag: boolean;
    public messageDateTime: string;
    public messageWrapper: string;
    public messageBody: string;
    public messageResponseTime: number;
    public messageSubTypeCode: string;
    public selected: boolean;
    public messagePriorityCode: string;
    public readDateTime: string;
    public archiveDateTime: string;
    public title: string;
    public schemaTypeCode: string;
    public indexName: string;
    public messagePriorityText: string;
    public sequenceNumber: number;
    public messageGroupId: string;
}