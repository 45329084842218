import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CsvExportTTYService {

    public download(filename: string, message: any) {

        const csvContent: string = this.ConvertToCSV(message);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename + '.csv');
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                //Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename + '.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    private addDoubleQuote(value: string) {
        return '\"' + value + '\"'
    }

    private ConvertToCSV(objArray: any) {
        const newLine: string = '\r\n';
        const csvSeperator: string = ',';

        let str = '';
        let row = '';
        let array = typeof objArray != 'object'
            ? JSON.parse(objArray) : objArray;

        for (var index in objArray) {
            row += this.addDoubleQuote(index) + csvSeperator;
        }
        row = row.slice(0, -1);
        str += row + newLine;

        var line = '';
        console.log(array);
        for (var index in array) {
            if (line != '') line += csvSeperator
            line += this.addDoubleQuote(array[index]);
        }
        str += line + newLine;
        return str;
    }
}