import { SearchDataView } from '../../message-management/search-messages/shared/search-data-view';
import { TtyMessageLogView } from './tty-message-log.view'
import { MessageLogService } from '../../../core/services/message-logs.service';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { SendMessageModel } from 'src/app/core/models/send-message.model';
import { ToastrService } from 'ngx-toastr';
import { GatewayService } from 'src/app/core/services/gateway.service';


export abstract class MessageBoxBase {

    protected filter: string = 'Filter';
    protected searchText: string = '';
    protected menuBar: string = '';
    public obj: SearchDataView = new SearchDataView();
    public dataAll: TtyMessageLogView[];
    public dataAllPaging: TtyMessageLogView[];
    public selectPage: number;
    public data$ = new BehaviorSubject<TtyMessageLogView[]>(null);
    public itemSelectedOutput: TtyMessageLogView;
    public itemSelectedId: string;
    public closeDetailFlag: boolean;
    public sendMessageFlag: boolean = false;
    public replyFlag: boolean;
    public subject: string;
    protected messageIdDash;
    protected messageIndexDash;
    protected actionDash;
    protected unreadCount: Subject<boolean> = new Subject<boolean>();
    private schemaTypeCode = "TTY";

    protected subjectReply = "Reply Message";
    protected subjectNewsend = "New Message";
    protected subjectResend = "Resend Message";
    protected subjectCopyResend = "Copy and Send";
    private readonly ALERT_HEADER: string = "Message Log";
    private readonly SUCCESS: string = "Send message command successfully send to gateway listener.";
    private readonly FAILED: string = "Send message command failed.";
    private readonly ALERT_SUCESS_REPROCESS: string = "Reprocess success.";
    private readonly ALERT_FAILED_REPROCESS: string = "Reprocess failed.";

    constructor(
        private dateConverterService: DateConverterService,
        private messageLogService: MessageLogService,
        private gatewayService: GatewayService,
        private toastr: ToastrService,
    ) { }

    abstract reLoadMessageBox();

    protected searchMessageLogBase() {
        var now = new Date();
        var previousMonth = new Date();
        previousMonth.setMonth(now.getMonth() - 1)
        let startDate = new Date(previousMonth.toUTCString());
        let endDate = new Date(now.toUTCString());
        return this.messageLogService.getMessageLogOverviews(
            this.obj.messageTypeCode,
            this.obj.messageSubCode,
            this.obj.senderIdentification,
            this.obj.receiverIdentification,
            this.dateConverterService.dateTimeToUTCString(startDate),
            this.dateConverterService.dateTimeToUTCString(endDate),
            this.obj.messageBody,
            this.obj.organisationId,
            this.obj.messageStatusCode,
            this.schemaTypeCode);
    }

    protected clearValue() {
        this.dataAll = [];
    }

    protected selectRow(itemSelectedOutput) {
        this.itemSelectedOutput = itemSelectedOutput;
    }

    protected reply() {
        if (this.itemSelectedOutput) {
            this.sendMessageFlag = true;
            this.closeDetailFlag = false;
            this.subject = this.subjectReply;
        }
    }

    public sendNewMessage(flag: any) {
        this.itemSelectedOutput = null;
        this.sendMessageFlag = true;
        this.closeDetailFlag = false;
        this.subject = this.subjectNewsend;
    }

    private messageFormat() {
        var date = new Date();
        let dateTime = new Date(date.toUTCString());
        let DD = ('0' + dateTime.getUTCDate()).substr(-2);
        let hh = ('0' + dateTime.getUTCHours()).substr(-2);
        let mm = ('0' + dateTime.getUTCMinutes()).substr(-2);
        let firstLine = this.itemSelectedOutput.messagePriorityCode + " " + this.itemSelectedOutput.receiverIdentification;
        let secondLine = "." + this.itemSelectedOutput.senderIdentification + " " + DD + hh + mm;
        let bodyFormat = firstLine + '\r' + secondLine + '\r' + this.itemSelectedOutput.messageBody + '\r';
        return bodyFormat;
    }

    protected resend() {
        if (this.itemSelectedOutput) {
            let bodyFormat = this.messageFormat();
            let dataSendMessage = new SendMessageModel();
            dataSendMessage.messageSchemaCode = this.schemaTypeCode;
            dataSendMessage.message = bodyFormat;
            this.gatewayService.sendMessage(dataSendMessage)
                .subscribe(
                    (response) => {
                        this.toastr.success(this.SUCCESS, this.ALERT_HEADER, {
                            timeOut: 2000
                        });
                    },
                    error => {
                        this.toastr.error(this.FAILED, this.ALERT_HEADER, {
                            timeOut: 2000
                        });
                    }
                );
        }
    }

    protected reprocess() {
        if (this.itemSelectedOutput) {
            let bodyFormat = this.messageFormat();
            let dataSendMessage = new SendMessageModel();
            dataSendMessage.messageSchemaCode = this.schemaTypeCode;
            dataSendMessage.message = bodyFormat;
            dataSendMessage.sender = this.itemSelectedOutput.senderIdentification;
            dataSendMessage.receiver = this.itemSelectedOutput.receiverIdentification;
            this.gatewayService.sendMessage(dataSendMessage)
                .subscribe(
                    (response) => {
                        this.toastr.success(this.ALERT_SUCESS_REPROCESS, this.ALERT_HEADER, {
                            timeOut: 2000
                        });
                    },
                    error => {
                        this.toastr.error(this.ALERT_FAILED_REPROCESS, this.ALERT_HEADER, {
                            timeOut: 2000
                        });
                    }
                );
        }
    }

    protected copyAndSend() {
        if (this.itemSelectedOutput) {
            this.sendMessageFlag = true;
            this.closeDetailFlag = false;
            this.subject = this.subjectCopyResend;
        }
    }

    protected closeSendMessageFlag(flag: any) {
        this.closeDetailFlagTrig(flag);
        this.sendMessageFlag = false;
    }

    protected showMessageLogDetailBase(itemDoubleClick) {
        if (itemDoubleClick) {
            this.getPagingTable(itemDoubleClick.messageGroupId, itemDoubleClick.id);
            this.itemSelectedId = itemDoubleClick.id;
            if (itemDoubleClick.readDateTime == null) {
                this.updateRead(this.itemSelectedId);
            }
            this.closeDetailFlag = false;
        } else if (this.itemSelectedOutput) {
            this.itemSelectedId = this.itemSelectedOutput.id;
            if (this.itemSelectedOutput.readDateTime == null) {
                this.updateRead(this.itemSelectedId);
            }
            this.closeDetailFlag = false;
        }
    }

    protected getPagingTable(messageGroupId: any, id: any) {
        const GROUP_ID = "00000000-0000-0000-0000-000000000000";
        let views = this.dataAllPaging.filter(d => d.messageGroupId == messageGroupId
            && d.messageGroupId != GROUP_ID);
        this.dataAllPaging = views.sort((a, b) => Number(a.sequenceNumber) - Number(b.sequenceNumber));
        this.selectPage = this.dataAllPaging.findIndex(m => m.id == id) + 1;
    }

    private closeDetailFlagTrig(flag) {
        this.closeDetailFlag = flag;
        if (flag) {
            this.reLoadMessageBox();
            this.itemSelectedOutput = null;
        }
    }

    protected updateRead(id) {
        this.messageLogService.updateRead(id)
            .subscribe(
                error => {
                    console.log(error);
                }
            );
    }

    protected updateArchived(id) {
        this.messageLogService.updateArchived(id)
            .subscribe(
                error => {
                    console.log(error);
                }
            );
    }

    protected archived() {
        if (this.itemSelectedOutput) {
            this.itemSelectedId = this.itemSelectedOutput.id;
            if (!this.itemSelectedOutput.archiveDateTime) {
                this.updateArchived(this.itemSelectedId);
                this.closeDetailFlagTrig(true);
            }
        }
    }

    protected functionOnBase($event) {
        this.menuBar = $event;
        if (this.menuBar == "reload") {
            this.reLoadMessageBox();
        }
        else if (this.menuBar == "sendnew") {
            this.sendNewMessage(null);
        }
        else if (this.menuBar == "reply") {
            this.reply();
        }
        else if (this.menuBar == "archived") {
            this.archived();
        }
        else if (this.menuBar == "resend") {
            this.resend();
        }
        else if (this.menuBar == "copy-resend") {
            this.copyAndSend();
        }
        else if (this.menuBar == "reprocess") {
            this.reprocess();
        }
    }
}
