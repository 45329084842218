import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { MessageBoxBase } from '../shared/message-box.base';
import { TtyMessageLogView } from '../shared/tty-message-log.view'
import { TtyMapperService } from '../shared/tty-mapper.service';
import { MessageLogOverviewModel } from '../../../core/models/message-log-overview.model'
import { MessageLogService } from '../../../core/services/message-logs.service';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GatewayService } from 'src/app/core/services/gateway.service';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'gw-inbox',
    templateUrl: 'inbox.component.html',
    styleUrls: ['../shared/message-box.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InboxComponent extends MessageBoxBase implements OnInit {

    public itemDoubleClick: TtyMessageLogView;
    private sender;
    private receiver;
    private messageGroupId;
    private unread;
    @Output() unreadInbox = new EventEmitter();

    constructor(
        dateConverterService: DateConverterService,
        messageLogService: MessageLogService,
        gatewayService: GatewayService,
        toastr: ToastrService,
        private router: Router,
        private ttyMapperService: TtyMapperService,
        private routeActivate: ActivatedRoute
    ) {
        super(dateConverterService, messageLogService, gatewayService, toastr);
    }

    ngOnInit() {
        this.searchMessageLog();
        this.closeDetailFlag = true;
        this.filter = 'Filter';
        this.searchText = '';
        this.routeActivate.params.subscribe(params => {
            this.actionDash = params['action'];
            this.messageIdDash = params['id'];
            this.messageIndexDash = params['index'];
            this.sender = params['s'];
            this.receiver = params['r'];
            this.messageGroupId = params['g'];
        });

        if (this.messageIdDash && this.messageIndexDash && this.actionDash == 'detail') {
            this.showDetailDashboard(this.messageIdDash, this.messageIndexDash, this.sender, this.receiver, this.messageGroupId);
        }
        else if (this.actionDash == 'sendnew') {
            this.sendNewMessage(null);
        }
        else if (this.actionDash == 'reply') {
            let dataReply = new TtyMessageLogView;
            dataReply.senderIdentification = this.sender;
            dataReply.receiverIdentification = this.receiver;
            this.itemSelectedOutput = dataReply;
            this.reply();
        }
    }

    reLoadMessageBox() {
        if (this.messageIdDash) {
            this.backToDashboard();
        } else {
            this.searchText = '';
            this.filter = 'Filter';
            setTimeout(() => {
                this.searchMessageLog();
            }, 3000);
        }
    }

    private backToDashboard() {
        let url = '/main/dashboard/summary';
        this.router.navigate([url]);
        this.actionDash = '';
        this.messageIdDash = '';
    }

    private closeSendMessageFlagInbox($event) {
        if (this.actionDash == 'sendnew' || this.actionDash == 'reply') {
            this.backToDashboard();
        } else {
            this.closeSendMessageFlag($event);
        }
    }

    private showDetailDashboard(id, index, sender, receiver, messageGroupId) {
        this.itemSelectedId = id;
        this.closeDetailFlag = false;
        let itemTemp = new TtyMessageLogView();
        itemTemp.id = id;
        itemTemp.indexName = index;
        itemTemp.senderIdentification = sender;
        itemTemp.receiverIdentification = receiver;
        itemTemp.messageGroupId = messageGroupId;
        this.itemSelectedOutput = itemTemp;
    }

    private showMessageLogDetail($event) {
        this.itemDoubleClick = $event;
        this.showMessageLogDetailBase(this.itemDoubleClick);
    }

    private searchMessageLog() {
        this.clearValue();
        this.searchMessageLogBase()
            .subscribe(
                (response: MessageLogOverviewModel[]) => {
                    this.dataAll = this.ttyMapperService.toTtyMessageViews(response, false);
                    this.dataAll = this.dataAll.filter(d => d.archiveDateTime == null);
                    this.unread = this.dataAll.filter(d => d.readDateTime == null).length;
                    this.data$.next(this.dataAll);
                    this.unreadCount.next(true);
                    this.getUnreadInbox();
                    this.dataAllPaging = this.ttyMapperService.toTtyMessagePagingViews(response);
                    if (this.sender) {
                        this.getPagingTable(this.messageGroupId, this.messageIdDash);
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

    private getUnreadInbox() {
        this.unreadInbox.emit(this.unread);
    }

    protected subjectTrig($event) {
        this.menuBar = $event;
        if (this.menuBar == "reload") {
            this.reLoadMessageBox();
        }
        else if (this.menuBar == "sendnew") {
            this.sendNewMessage(null);
        }
        else if (this.menuBar == "reply") {
            this.reply();
        }
        else if (this.menuBar == "archived") {
            this.archived();
        }
        else if (this.menuBar == "resend") {
            this.resend();
        }
        else if (this.menuBar == "copy-resend") {
            this.copyAndSend();
        }
        else if (this.menuBar == "reprocess") {
            this.reprocess();
        }
    }
}
