import { Injectable } from '@angular/core';
import { MessageLogOverviewModel } from '../../../core/models/message-log-overview.model';
import { DateConverterService } from '../../../core/utils/date-converter.service';
import { MessageLogModel } from '../../../core/models/message-log.model';
import { TtyMessageLogView } from '../../tty-message/shared/tty-message-log.view'
import { TtyMessageLogDetailView } from '../../tty-message/shared/tty-message-log-detail.view'

@Injectable()
export class TtyMapperService {

    private schemaTypeCodeTTY = "TTY";
    private prioritySS = 'SS';
    private priorityQS = 'QS';
    private priorityQC = 'QC';
    private priorityQU = 'QU';
    private priorityQX = 'QX';
    private priorityQK = 'QK';
    private priorityQD = 'QD';

    constructor(private dateConverterService: DateConverterService) { }

    private formatMessgaeDetail(message: string): string {

        const crLf: string = "\r\n";
        let splitEdifacts: string[] = message.split(crLf)
        let result: string = "";
        for (let i = 0; i < splitEdifacts.length; i++) {
            if (splitEdifacts[i].length > 0 && splitEdifacts[i] != crLf) {
                result += splitEdifacts[i].replace(/[\r]/g, "<br/>") + "<br/>";
            }
        }
        return result;
    }

    public toTtyMessageViews(models: MessageLogOverviewModel[], outgoingFlag: boolean): TtyMessageLogView[] {
        if (!models) {
            return null;
        }
        let views: TtyMessageLogView[] = new Array();
        let i = 0;
        for (let model of models) {
            if (model.outgoingFlag != outgoingFlag) {
                continue;
            }
            let view: TtyMessageLogView = new TtyMessageLogView();
            view.no = ++i
            view = this.toTtyMessageLogView(model, view); 
            views.push(view);
        }
        return views;
    }

    public toTtyMessagePagingViews(models: MessageLogOverviewModel[]): TtyMessageLogView[] {
        if (!models) {
            return null;
        }
        let views: TtyMessageLogView[] = new Array();
        let i = 0;
        for (let model of models) {
            let view: TtyMessageLogView = new TtyMessageLogView();
            view.no = ++i
            view = this.toTtyMessageLogView(model, view);
            views.push(view);
        }
        return views;
    }

    private toTtyMessageLogView(model: MessageLogOverviewModel, view: TtyMessageLogView): TtyMessageLogView {
         
        view.id = model.id;
        view.messageTypeCode = model.messageTypeCode;
        view.senderIdentification = model.senderIdentification;
        view.receiverIdentification = model.receiverIdentification;
        view.providerCode = model.providerCode;
        view.messageStatusCode = model.messageStatusCode;
        view.outgoingFlag = model.outgoingFlag;
        view.messageDateTime = this.dateConverterService.convertDate(model.messageDateTime);
        view.messageWrapper = model.messageWrapper;
        view.messageBody = model.messageBody;
        view.messageResponseTime = model.messageResponseTime;
        view.messageSubTypeCode = model.messageSubTypeCode;
        view.messagePriorityCode = model.messagePriorityCode;
        view.messagePriorityText = this.priorityMapper(model.messagePriorityCode);
        view.readDateTime = this.dateConverterService.convertDate(model.readDateTime);
        view.archiveDateTime = this.dateConverterService.convertDate(model.archiveDateTime);
        view.schemaTypeCode = model.schemaTypeCode;
        view.indexName = model.indexName;
        view.sequenceNumber = model.sequenceNumber;
        view.messageGroupId = model.messageGroupId;
        return view;
    }

    public toTtyMessageLogDetailViews(model: MessageLogModel): TtyMessageLogDetailView {
        let view: TtyMessageLogDetailView = new TtyMessageLogDetailView();
        view.id = model.id;
        view.messageWrapper = model.messageWrapper;
        view.messageBody = this.formatMessgaeDetail(model.messageBody);
        view.messagePairId = model.messagePairId;
        view.messageDateTime = this.dateConverterService.convertDateTime(model.messageDateTime);
        view.messageTypeCode = model.messageTypeCode;
        view.senderIdentification = model.senderIdentification;
        view.receiverIdentification = model.receiverIdentification;
        view.messagePriorityCode = model.messagePriorityCode;
        view.messagePriorityText = this.priorityMapper(model.messagePriorityCode);
        view.readDateTime = this.dateConverterService.convertDate(model.readDateTime);
        view.archiveDateTime = this.dateConverterService.convertDate(model.archiveDateTime);
        view.messageSubTypeCode = model.messageSubTypeCode;
        return view;
    }

    public priorityMapper(priority) {
        if (priority == this.prioritySS || priority == this.priorityQS || priority == this.priorityQC) {
            return 'Highest';
        }
        else if (priority == this.priorityQU || priority == this.priorityQX) {
            return 'Urgent';
        }
        else if (priority == this.priorityQK) {
            return 'Normal';
        }
        else if (priority == this.priorityQD) {
            return 'Deferred';
        }
        else {
            return 'No Priority';
        }
    }

    public priorityMapperColor(priority) {
        if (priority == 'Highest') {
            return '#e60000';
        }
        else if (priority == 'Urgent') {
            return '#ff8c00';
        }
        else if (priority == 'Normal') {
            return '#6b8e23';
        }
        else if (priority == 'Deferred') {
            return '#1e90ff';
        }
        else if (priority == 'No Priority'){
            return '#808080';
        }
    }
}