import { SearchDataDateView } from './search-data-date-view';
export class SearchDataView {
    constructor() {
        this.searchDate = new SearchDataDateView();
    }
    public organisationId: string;
    public searchDate: SearchDataDateView;
    public senderIdentification: string;
    public receiverIdentification: string;
    public messageTypeCode: string;
    public messageSubCode: string;
    public messageBody: string;
    public messageStatusCode: string;
    public schemaTypeCode: string;
    public responseSize: number;
}