import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { _runtimeChecksFactory } from '@ngrx/store/src/runtime_checks';

export abstract class OopsComponentFormBase implements OnInit {

    private _formGroup: FormGroup;
    private _processing: boolean

    constructor(private _formBuilder: FormBuilder) {
        this._processing = false;
    }

    ngOnInit(): void {
        this.initForm();
    }

    public abstract initForm();

    public get processing() {
        return this._processing;
    }

    public get formGroup(): FormGroup {
        return this._formGroup;
    }

    public set formGroup(value: FormGroup) {
        this._formGroup = value;
    }

    public setformGroupValue(value: any, validator = null) {
        this._formGroup = this._formBuilder.group(value, validator);
    }

    public validControl(name: string): boolean {
        return this._formGroup.controls[name].invalid &&
            (this._formGroup.controls[name].dirty || this._formGroup.controls[name].touched)
    }

    public getError(name: string): ValidationErrors {
        return this._formGroup.controls[name].errors
    }

    public validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    public validForm(): boolean {
        return this._formGroup.valid;
    }

    public startProcessing(): void {
        this._processing = true;
    }

    public completeProcessing(): void {
        this._processing = false;
    }
}