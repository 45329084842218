import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})

export class MessageListTruncatePipe implements PipeTransform {

    transform(message: string): string {
        const crLf: string = "'";
        let splitEdifacts: string[] = message.split(crLf, 2)
        let result: string = "";
        for (let i = 0; i < splitEdifacts.length; i++) {
            if (splitEdifacts[i].length > 0) {
                result += splitEdifacts[i] + "<br>";
            }
        }
        return result;
    }
}
