import { Injectable } from '@angular/core';

@Injectable()
export class DataTableHelper {
     public searchDataTablePipe(params: any[], filterPipe: any, data: any) {
        let results: any = data;

        for (let i = 0; i < params.length; i++) {
            let dataValue = params[i];
            let key = params[++i];
            if (dataValue == true || dataValue == false ) {
                    results = filterPipe.transform(results, dataValue, key);
            }
            else {
                if (dataValue && dataValue.length > 0) {
                    results = filterPipe.transform(results, dataValue, key);
                }
            }
        }

        return results;
    }
}