import * as _ from "lodash";
import {Pipe, PipeTransform} from "@angular/core";
import { FilterService } from '../../../shared/utils/filter-service';

@Pipe({
    name: "dataFilter"
})
export class OrganisationSelectorPipe implements PipeTransform {

    constructor(private filterService: FilterService) {

    }

    transform(array: any[], query: string, fieldName: string): any {
        if (query != null) {
            if (fieldName == "ORGANISATION") {
               return _.filter(array, row => this.filterService.filterString(query, row.organisationName + " " + row.iataNumber));
            } 
        }
        return array;
    }
}