import { Component, OnInit, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MessageBoxBase } from '../shared/message-box.base';
import { TtyMapperService } from '../shared/tty-mapper.service';
import { TtyMessageLogView } from '../shared/tty-message-log.view'
import { MessageLogOverviewModel } from '../../../core/models/message-log-overview.model'
import { MessageLogService } from '../../../core/services/message-logs.service';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { GatewayService } from 'src/app/core/services/gateway.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'gw-inbox-archived',
    templateUrl: 'inbox-archived.component.html',
    styleUrls: ['../shared/message-box.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InboxArchivedComponent extends MessageBoxBase implements OnInit {

    public itemDoubleClick: TtyMessageLogView;
    public dataChange: boolean = false;

    reLoadMessageBox() {
        this.searchText = '';
        this.filter = 'Filter';
        setTimeout(() => {
            this.searchMessageLog();
        }, 2000);
    }

    constructor(
        dateConverterService: DateConverterService,
        messageLogService: MessageLogService,
        gatewayService: GatewayService,
        toastr: ToastrService,
        private ttyMapperService: TtyMapperService
    ) {
        super(dateConverterService, messageLogService, gatewayService, toastr)
    }

    ngOnInit() {
        this.searchMessageLog();
        this.closeDetailFlag = true;
        this.searchText = '';
        this.filter = 'Filter';
    }

    public showMessageLogDetail($event) {
        this.itemDoubleClick = $event;
        this.showMessageLogDetailBase(this.itemDoubleClick);
    }

    public searchMessageLog() {
        this.clearValue()
        this.searchMessageLogBase()
            .subscribe(
                (response: MessageLogOverviewModel[]) => {
                    this.dataAll = this.ttyMapperService.toTtyMessageViews(response, false);
                    this.dataAll = this.dataAll.filter(d => d.archiveDateTime != null);
                    this.data$.next(this.dataAll);
                    this.dataAllPaging = this.ttyMapperService.toTtyMessagePagingViews(response);
                },
                error => {
                    console.log(error);
                }
            );
    }

    private subjectTrig($event) {
        this.functionOnBase($event);
    }

}
