import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DateConverterService {

    private readonly DATE_FORMAT: string = "YYYY-MM-DD";
    private readonly DATETIME_FORMAT: string = "YYYY-MM-DD hh:mm A";
    private readonly DATETIME_FORMAT_24: string = "YYYY-MM-DD HH:mm";
    private readonly DATETIME_FORMAT_SECOND: string = "YYYY-MM-DD hh:mm:ss A"
    private readonly DATETIME_FORMAT_SECOND_MILISECOND: string = "YYYY-MM-DD hh:mm:ss.SSS A"

    public dateTimeToUTCString(date: Date) {
        if (!date) {
            return "";
        }
        return date.toISOString();
    }

    public toDateFormat(date: Date) {
        return moment(date).format(this.DATE_FORMAT);
    }

    public convertDate(dateValue: Date) {
        if (dateValue) {
            return moment(dateValue).format(this.DATETIME_FORMAT);
        } else {
            return null;
        }

    }

    public convertDateTime(dateValue: Date) {
        if (dateValue) {
            return moment(dateValue).format(this.DATETIME_FORMAT_SECOND);
        } else {
            return null;
        }
    }

    public toUtcDate(dateValue: string): Date {
        if (dateValue) {
            return new Date(dateValue);
        }
        return null;
    }

    public toSinceDate(dateValue: Date, withoutSuffix = false, daytoShowFullDate = 0): string {
        if (!dateValue) {
            return "";
        }
        let days = this.getNumberOfDays(dateValue);
        if (daytoShowFullDate !== 0 && days >= daytoShowFullDate) {
            return this.convertDateTime(dateValue);
        }
        return moment(dateValue).fromNow(withoutSuffix);
    }

    public getNumberOfDays(dateValue: Date): number {

        let now = moment(new Date());
        let endDate = moment(dateValue);
        var duration = moment.duration(now.diff(endDate));
        return duration.asDays();
    }

    public toSinceDateShort(dateValue: Date): string {
        if (!dateValue) {
            return "";
        }
        let sinceTime = moment(dateValue).fromNow(true);
        return this.formatSinceDate(sinceTime);
    }

    private formatSinceDate(date): string {
        let strDate = date.split(" ");
        let format = "";
        if (strDate[0] == "un" || strDate[0] == "a") {
            strDate[0] = "1";
            return strDate[0] + " " + this.getDateAbbreviation(strDate[0], strDate[1][0]);
        }
        return strDate[0] + " " + this.getDateAbbreviation(strDate[0], strDate[1][0]);
    }
    private getDateAbbreviation(time: number, timeString: string): string {
        const fewSecond = "f"
        const second = "s";
        const minute = "m";
        const hour = "h";
        const day = "d";

        switch (timeString) {
            case fewSecond:
                return this.isPural(time, "sec");
            case second:
                return this.isPural(time, "sec");
            case minute:
                return this.isPural(time, "min");
            case hour:
                return this.isPural(time, "hour");
            case day:
                return this.isPural(time, "day");
            default:
                return timeString;
        }
    }
    private isPural(time: number, value: string): string {
        const pural = "s";
        if (time > 1) {
            return value + pural;
        }
        return value;
    }
    public getNumberOfSeconds(dateValue: Date): number {

        let now = moment(new Date());
        let endDate = moment(dateValue);
        var duration = moment.duration(now.diff(endDate));
        return duration.asSeconds();
    }
}