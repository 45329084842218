import { Injectable } from '@angular/core';
import { OrganisationView } from './organisation-view';
import { OrganisationModel } from 'src/app/core/models/organisation-model';

@Injectable()
export class OrganisationMapperService {
    public toOrganisationViews(models: OrganisationModel[]): OrganisationView[] {
        if (!models) {
            return null;
        }
        
        let views = new Array();

        for (let model of models) {
            views.push(this.toOrganisationView(model));
        }

        return views;
    }

    public toOrganisationView(model: OrganisationModel):  OrganisationView {
        if (!model)  {
            return null;
        }

        let view = new OrganisationView();
        view.iataNumber = model.iataNumber;
        view.organisationId = model.id;
        view.organisationName = model.organisationCallName;
        return view;
    }
}
