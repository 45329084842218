import { Injectable } from '@angular/core';

import * as _ from "lodash";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class StringHelperService {

    public undefinedToEmpty(value: string) {
        if (!value) {
            return "";
        }
        return value;
    }

    public stringToBoolean(stringValue: string): boolean {
        try {
            return JSON.parse(stringValue);
        }
        catch (e) {
            return false;
        }
    }

    public stringToInt(value: string): number {
        if (!value) {
            return 0;
        }
        return Number.parseInt(value);
    }

    public toCamelCase(value: string): string {
        return _.camelCase(value);
    }

    public toPascalCase(value: string): string {
        return _.startCase(_.camelCase(value)).replace(/ /g, '');
    }

    public amPmToDateString(value: string): string {
        if (!value) {
            return "";
        }
        return moment(new Date(value)).toISOString();
    }
}