import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './shared/layout/main/main.component';
import { AuthComponent } from './modules/auth/auth.component';
import { GatewayGuard } from './core/guards/gateway.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth'
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [GatewayGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'message-management',
        loadChildren: () => import('./modules/message-management/message-management.module').then(m => m.MessageManagementModule)
      },
      {
        path: 'listener-management',
        loadChildren: () => import('./modules/listener-management/listener-management.module').then(m => m.ListenerManagementModule)
      },
      {
        path: 'tty-message',
        loadChildren: () => import('./modules/tty-message/tty-message.module').then(m => m.TTYMessageModule)
      },
      {
        path: 'system-settings',
        loadChildren: () => import('./modules/system-settings/system-settings.module').then(m => m.SystemSettingsModule)
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
