import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OopsComponentFormBase } from '../../../../core/base/oops-component-form-base';
import { GatewayService } from '../../../../core/services/gateway.service'
import { TtyMessageLogView } from '../tty-message-log.view'
import { SendMessageModel } from '../../../../core/models/send-message.model'
import { ToastrService } from 'ngx-toastr';
import { sendMessageFormOption } from '../../shared/tty-message-config';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { OrganisationService } from '../../../../core/services/organisation.service';

declare var $: any;

@Component({
    selector: 'gw-send-message',
    templateUrl: 'send-message.component.html'
})
export class SendMessageComponent extends OopsComponentFormBase {
    private readonly ALERT_HEADER: string = "Message Log";
    private readonly SUCCESS: string = "Send message command successfully send to gateway listener.";
    private readonly FAILED: string = "Send message command failed.";

    @Output() closeSendMessageFlag = new EventEmitter();
    @Input() itemSelected: TtyMessageLogView;
    @Input() subject: string;
    public messageBodyLength: number = 0;
    public currentLineLength: number = 0;
    public messageBodyTemp: string = "";
    public dataSendMessage: SendMessageModel;
    public sendnew;
    public ruler = "....+....1....+....2....+....3....+....4....+....5....+....6....+....7....+....8....+....9";

    constructor(private gatewayService: GatewayService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private authService: AuthService,
        private organisationService: OrganisationService) {
        super(formBuilder);
    }

    public initForm() {
        this.formSetup();
        this.sendnew = false;
        if (this.subject == "Reply Message") {
            this.formGroup.get('sender').setValue(this.itemSelected.receiverIdentification);
            this.formGroup.get('receiver').setValue(this.itemSelected.senderIdentification);
        }
        else if (this.subject == "Resend Message" || this.subject == "Copy and Send") {
            this.formGroup.get('sender').setValue(this.itemSelected.senderIdentification);
            this.formGroup.get('receiver').setValue(this.itemSelected.receiverIdentification);
            this.formGroup.get('priority').setValue(this.itemSelected.messagePriorityCode);
            this.formGroup.get('messageBody').setValue(this.formatMessage(this.itemSelected.messageBody));
            if (this.subject == "Resend Message") {
                this.sendMessage(this.formGroup.value);
            }
        }
        else {
            this.subject = "New Message"
            this.sendnew = true;
            let organisationId = this.authService.getUserInformation().organisationId;
            this.organisationService.getOrganisationById(organisationId)
                .subscribe(
                    (responses) => {
                        let teletypeAddress = responses[0].teletypeAddress;
                        this.formGroup.get('sender').setValue(teletypeAddress);
                    }
                );
        }
    }

    public closeSendMessage() {
        this.closeSendMessageFlag.emit(true);
    }

    onSubmit(replyData) {
        if (this.formGroup.get('sender').errors?.pattern) {
            this.toastr.error("Wrong sender format", "Message Error", {
                timeOut: 2000
            });
        }
        else if (this.formGroup.invalid) {
            this.toastr.error("Please complete all information", "Message Error", {
                timeOut: 2000
            });
        }
        else {
            let resultValidate = this.validateReceiver(this.formGroup.get('receiver'));
            if (!resultValidate) {
                this.toastr.error("Wrong receiver format", "Message Error", {
                    timeOut: 2000
                });
            }
            else {
                let dataSubmit = replyData;
                let receiverList = dataSubmit.receiver.split(',');
                receiverList.forEach(receiver => {
                    dataSubmit.receiver = receiver;
                    this.sendMessage(dataSubmit);
                });
            }
        }
    }

    private formatMessageSend(dataMessage) {
        var date = new Date();
        let dateTime = new Date(date.toUTCString());
        let DD = ('0' + dateTime.getUTCDate()).substr(-2);
        let hh = ('0' + dateTime.getUTCHours()).substr(-2);
        let mm = ('0' + dateTime.getUTCMinutes()).substr(-2);
        let msgReplace = dataMessage.messageBody.toLocaleUpperCase();
        msgReplace = msgReplace.split(/Á|À|Ã|Â/gi).join("A");
        msgReplace = msgReplace.split(/É|È|Ë|Ê/gi).join("E");
        msgReplace = msgReplace.split(/Î|Í|Ï/gi).join("I");
        msgReplace = msgReplace.split(/Ó|Ô|Õ/gi).join("O");
        msgReplace = msgReplace.split(/Ù|Ú|Û/gi).join("U");
        msgReplace = msgReplace.split(/Ç/gi).join("C");
        msgReplace = msgReplace.split(/Æ|Ä/gi).join("AE");
        msgReplace = msgReplace.split(/Ö/gi).join("OE");
        msgReplace = msgReplace.split(/Ü/gi).join("UE");
        msgReplace = msgReplace.split(/ß/gi).join("SS");
        msgReplace = msgReplace.split(/Ñ/gi).join("N");
        msgReplace = msgReplace.split(/Š/gi).join("S");
        msgReplace = msgReplace.split(/Ž/gi).join("Z");
        msgReplace = msgReplace.split(/Ý/gi).join("Y");

        let firstLine = dataMessage.priority + " " + dataMessage.receiver;
        let secondLine = "." + dataMessage.sender + " " + DD + hh + mm;
        let bodyFormat = firstLine + '\r' + secondLine + '\r' + msgReplace + '\r';
        return bodyFormat;
    }

    private sendMessage(dataMessage) {
        let pass = this.validateMessage(dataMessage.messageBody);
        if (pass) {
            let dataMessageFormat = this.formatMessageSend(dataMessage);
            this.dataSendMessage = new SendMessageModel();
            this.dataSendMessage.messageSchemaCode = "TTY";
            this.dataSendMessage.message = dataMessageFormat;
            this.gatewayService.sendMessage(this.dataSendMessage)
                .subscribe(
                    (response) => {
                        this.toastr.success(this.SUCCESS, this.ALERT_HEADER, {
                            timeOut: 2000
                        });
                        setTimeout(() => {
                            this.formGroup.reset();
                            this.closeSendMessage();
                        }, 3000);
                    },
                    error => {
                        this.toastr.error(this.FAILED, this.ALERT_HEADER, {
                            timeOut: 2000
                        });
                    }
                );
        }
    }

    public formSetup() {
        this.setformGroupValue(sendMessageFormOption);
        this.formGroup.get('messageBody').valueChanges
            .subscribe(val => {
                if (val) {
                    this.validateTyping(val);
                }
            })
    }

    private formatMessage(message) {
        const crLf: string = "'";
        let splitEdifacts: string[] = message.split(crLf)
        let result: string = "";
        for (let i = 0; i < splitEdifacts.length; i++) {
            if (splitEdifacts[i].length > 0) {
                result += splitEdifacts[i] + "'\n";
            }
        }
        return result;
    }

    private validateMessage(message) {
        if (message.length > 3000) {
            this.toastr.error("Message maximum 3,000 characters", "Message Error", {
                timeOut: 2000
            });
            return false;
        } else {
            let msgArray = message.split('\n');
            for (let msgLine of msgArray) {
                if (msgLine.length > 64) {
                    this.toastr.error("Message 64 characters per line", "Message Error", {
                        timeOut: 2000
                    });
                    return false;
                }
            }
            return true;
        }
    }

    private validateReceiver(receivers) {
        let result = true;
        let regex = '(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)|(^.{7}$)';
        let receiverList = receivers.value.split(',');
        receiverList.forEach(receiver => {
            let temp = receiver.match(regex);
            if (!temp) {
                result = false;
            }
        });
        return result;
    }

    private validateTyping(val) {
        let msgBody = val;
        let msgArray = msgBody.split('\n');
        this.messageBodyLength = val.replace(/[\r\n]+/gm, "").length;
        var cursorPosition = $('#messageBody').prop("selectionStart");
        let currentLength = 0;
        let isValid = true;
        for (let msgLine of msgArray) {
            let length = msgLine.length;
            if (cursorPosition >= currentLength && cursorPosition <= (currentLength + length)) {
                if (length > 64 || this.messageBodyLength > 3000) {
                    isValid = false;
                    this.formGroup.get('messageBody').setValue(this.messageBodyTemp, { emitEvent: false });
                    this.toastr.error("Message maximum 64 characters per line and 3,000 characters in total", "Message Error", {
                        timeOut: 1000
                    });
                } else {
                    this.currentLineLength = length;
                }
            }
            currentLength += length + 1;
        }
        if (isValid) {
            this.messageBodyTemp = val;
        }
    }
}
