import { Validators } from '@angular/forms';
export const datatableTtyMessageOption = {
    ajax: null,
    responsive: true,
    lengthChange: false,
    select: 'single',
    pagingType: 'simple_numbers',
    order: [[5, 'desc']],
    columnDefs: [
        {
            "targets": [4],
            "orderable": false
        },
        {
            "targets": [6, 7, 8],
            "visible": false
        },
        {
            "width": "12%",
            "targets": [0, 1, 2, 3]
        }
    ],
    dom:
        /*	--- Layout Structure 
            --- Options
            l	-	length changing input control
            f	-	filtering input
            t	-	The table!
            i	-	Table information summary
            p	-	pagination control
            r	-	processing display element
            B	-	buttons
            R	-	ColReorder
            S	-	Select

            --- Markup
            < and >				- div element
            <"class" and >		- div with a class
            <"#id" and >		- div with an ID
            <"#id.class" and >	- div with an ID and a class

            --- Further reading
            https://datatables.net/reference/option/dom
            --------------------------------------
         */
        "<'row'<'col-sm-12 px-3 py-3'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>" +
        "<'row mt-1'<'col-sm-12 d-flex align-items-center'l>>",
    language: {
        /* change the default text for 'next' and 'previous' with icons */
        paginate: {
            previous: "<i class='fal fa-chevron-left'></i>",
            next: "<i class='fal fa-chevron-right'></i>"
        },
        processing: '<div class="d-flex align-items-center justify-content-center fs-lg"><div class="spinner-border spinner-border-sm text-primary mr-2" role="status"><span class="sr-only"> Loading...</span></div> Processing...</div>',
        /* change text for zero records */
        zeroRecords: "No records to display"
    },
    buttons: []
};

export const tabsOption = [
    {
        id: 1,
        text: "All"
    },
    {
        id: 2,
        text: "Unread"
    }
];

export const sendMessageFormOption = {
    sender: ['', Validators.compose([
        Validators.required,
        Validators.pattern('(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)|(^.{7}$)')
    ])],
    receiver: ['', Validators.required],
    priority: ['QK', Validators.required],
    messageBody: ['', Validators.compose([
        Validators.required
    ])]
};
