import { OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core'
import { DataTableDirective } from 'angular-datatables';
import { Observable } from 'rxjs';

declare var $: any;

export abstract class DatatableComponentBase implements AfterViewInit {

    @ViewChild(DataTableDirective, { static: false })
    datatableElement: DataTableDirective;
    _datatableInitialized: boolean = false;

    ngAfterViewInit(): void {
        this.cleanDatatable();
    }

    public abstract cleanDatatable()
    protected get jQuery(): any {
        return $
    }
    protected destroyDatatable() {

        let instance = this.getDataTableAPI();
        if (instance != null) {
            instance.then((dtInstance: DataTables.Api) => {
                dtInstance.clear().draw();
            });
        }
    }

    protected reloadData() {
        let instance = this.getDataTableAPI();
        if (instance != null) {
            instance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.reload();
            });
        }
    }
    public getSelectedData<T>(): Observable<T[]> {

        const selectRowObservable = new Observable<T[]>(observer => {
            let instance = this.getDataTableAPI();
            if (instance != null) {
                instance.then((dtInstance: DataTables.Api) => {
                    let items: T[] = dtInstance.rows({ selected: true }).data().toArray();
                    observer.next(items);
                    observer.complete();
                });
            }
        });
        return selectRowObservable;
    }
    protected getDatatableInstance() {
        return this.datatableElement?.dtInstance;
    }
    private getDataTableAPI(): Promise<DataTables.Api> {
        if (this._datatableInitialized) {
            if (this.datatableElement && this.datatableElement.dtInstance) {
                return this.datatableElement.dtInstance;
            }
        } else {
            this._datatableInitialized = true;
            return null;
        }
    }
}