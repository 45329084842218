import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'summary',
        pathMatch: 'full'
    },
    {
        path: 'summary',
        loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule),
        data: {
            breadcrumbs: [
                {
                    name: 'Dashboard',
                    path: '/main/dashboard/summary'
                },
                {
                    name: 'Summary',
                    path: '/main/dashboard/summary'
                },
            ]
        }
    },
    {
        path: 'error-list',
        loadChildren: () => import('./error-list/error-list.module').then(m => m.ErrorListModule),
        data: {
            breadcrumbs: [
                {
                    name: 'Dashboard',
                    path: '/main/dashboard'
                },
                {
                    name: 'Error List',
                    path: '/main/dashboard/error-list'
                },
            ]
        }
    }
];

export const routing = RouterModule.forChild(routes);


