import { OnInit, Component, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { DatatableComponentBase } from 'src/app/core/base/datatable-component-base';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { DataTableHelper } from 'src/app/shared/utils/datatable.helper';
import { OrganisationModel } from 'src/app/core/models/organisation-model';

import { OrganisationView } from 'src/app/modules/dashboard/organisation-selector/shared/organisation-view';
import { OrganisationMapperService } from 'src/app/modules/dashboard/organisation-selector/shared/organisation-mapper.service';

import { OrganisationSelectorPipe } from './organisation-selector.pipe';
import { OrganisationSelectorService } from 'src/app/core/services/organisation-selector.service';

@Component({
    selector: 'gw-organisation-selector',
    templateUrl: 'organisation-selector.component.html',
    styles: [`
        :host >>> .popover {
            max-width: 100%;
        }

        :host >>> .popover-body:not(:empty) {
            padding: 1rem 1rem 1rem;
        }
    `]
})
export class OrganisationSelectorComponent extends DatatableComponentBase implements OnInit, OnDestroy {
    public organisationName: string;
    public organisation$ = new BehaviorSubject<OrganisationView[]>(null);
    public organisations = new Array<OrganisationView>();

    @Input() dashboardName: string;
    @Output() onSelect = new EventEmitter<string>();

    public currentDateTime: string;
    public filterOrganisation: string;

    @ViewChild("pop") pop: any;

    constructor(private organisationService: OrganisationService,
        private mapperservice: OrganisationMapperService,
        private dateConverterService: DateConverterService,
        private datatableHelper: DataTableHelper,
        private filterPipe: OrganisationSelectorPipe,
        public organisationSelectorService: OrganisationSelectorService) {
        super();
    }

    ngOnInit(): void {
        this.searchAllOrganisations();
    }

    ngOnDestroy(): void {
        this.destroyDatatable();
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    public searchAllOrganisations() {
        this.organisationService.getAllActiveOrganisation()
            .subscribe(
                (responses: OrganisationModel[]) => {
                    this.destroyDatatable();
                    let views = this.mapperservice.toOrganisationViews(responses);
                    this.organisation$.next(views);
                    this.currentDateTime = this.getCurrentDateTime();
                }
            );
    }

    private getCurrentDateTime() {
        let displayDate = new Date();
        return this.dateConverterService.convertDate(displayDate);
    }

    public resetOrganisation() {
        this.organisationSelectorService.nextOrganisation(new OrganisationView);
    }

    public applyPipeFilter() {
        let args: any[] = [
            this.filterOrganisation, 'ORGANISATION'
        ]

        if (!args) {
            return this.organisation$.value;
        }
        return this.datatableHelper.searchDataTablePipe(args, this.filterPipe, this.organisation$.value);
    }

    public selectOrganisation(organisation: OrganisationView) {
        this.organisationSelectorService.nextOrganisation(organisation);
        this.pop.hide();
    }
}
