//How to use.
//Install "npm install --save pdfmake" is required.
//  1. Inject service to component.
//  2. call function downloadPdf(exportContent). The export content can found in PDFMake document.
//  https://pdfmake.github.io/docs/ 
import { Injectable } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})
export class PdfExportService {

    public download(filename: string, exportContent: any) {
        pdfMake.createPdf(exportContent).download(filename + '.pdf');
    }
}