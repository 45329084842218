export const APP_CONFIG = {

  app: 'OopsGateway',
  appName: 'Admin Portal',
  user: '',
  email: '',
  twitter: '',
  avatar: '',
  version: '2.0.0',
  bs4v: '4.3',
  logo: 'logo.png',
  logoM: 'logo.png',
  // tslint:disable-next-line:max-line-length
  copyright: 'Oops Gateway Admin Portal ©{{YEAR}} TIK Systems (Thailand) Co., Ltd. All Rights Reserved.',
  // tslint:disable-next-line:max-line-length
  // copyrightInverse: '2019 © SmartAdmin by&nbsp;<a href="https://www.gotbootstrap.com" class="text-white opacity-40 fw-500" title="gotbootstrap.com" target="_blank">gotbootstrap.com</a>'
};
