import {
    Directive,
    ElementRef,
    OnInit,
    AfterViewInit,
    Output,
    EventEmitter,
    Input,
    Self,
    Optional
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Select2Data } from './select2-data';

declare var $: any;

@Directive({
    selector: '[select2]'
})
export class Select2Directive implements OnInit, AfterViewInit {

    private _select2Options: any;
    private _optionList: Select2Data[];

    @Input()
    set optionList(values: Select2Data[]) {
        this._optionList = values;
        this.reInitializeSelect2();
    }

    @Input()
    set option(value: any) {
        this._select2Options = value;
    }

    @Input()
    set data(value: string | Array<string>) {
        this.setValue(value);
    }
    @Output()
    dataChange: EventEmitter<string | Array<string>> = new EventEmitter();

    constructor(private el: ElementRef,
        @Self()
        @Optional()
        private control: NgControl) { }

    ngOnInit(): void {
        this.initializeSelect2();
    }

    ngAfterViewInit(): void {

        $(this.el.nativeElement).on('select2:open', (event) => {
            this.markControlAsTouch();
        });

        $(this.el.nativeElement).on('select2:select', (event) => {
            this.notifyChange();
        });

        $(this.el.nativeElement).on('select2:clear', (event) => {
            this.dataChange.emit(null);
        });

        $(this.el.nativeElement).on('select2:unselect', (event) => {
            this.notifyChange();
        });
    }

    private notifyChange() {
        let currentData = $(this.el.nativeElement).val();
        this.dataChange.emit(currentData);

    }

    private setReactiveFormValue(value: string | Array<string>) {
        if (!this.control || !this.control.control) {
            return;
        }
        this.control.control.setValue(value);
    }

    private markControlAsTouch() {
        if (!this.control || !this.control.control) {
            return;
        }
        this.control.control.markAsTouched();
    }

    private setValue(value: string | Array<string>) {
        this.setReactiveFormValue(value);
        $(this.el.nativeElement).val(value).trigger('change');
    }

    private initializeSelect2(): void {
        this._select2Options.data = this.generateDataFromOption();
        $(this.el.nativeElement).select2(this._select2Options);
    }

    private reInitializeSelect2(): void {
        if (!this._select2Options) {
            return;
        }

        if (this.select2InstanceCreated()) {
            this.select2InstanceDestroy();
            $(this.el.nativeElement).empty();
            this._select2Options.data = this.generateDataFromOptionList();
            $(this.el.nativeElement).select2(this._select2Options);
        }
    }

    private select2InstanceDestroy() {
        $(this.el.nativeElement).select2('destroy');
    }

    private select2InstanceCreated(): boolean {
        return $(this.el.nativeElement).hasClass("select2-hidden-accessible");
    }

    private generateDataFromOptionList() {
        if (!this._optionList) {
            return;
        }
        let datas = [];
        for (let option of this._optionList) {
            datas.push({
                id: option.id,
                text: option.text
            });
        }
        return datas;
    }

    private generateDataFromOption() {

        let element = $(this.el.nativeElement);
        if (!element || element.length == 0) {
            return null;
        }
        let datas = [];
        if (!element[0].options || element[0].options.length == 0) {
            return;
        }
        for (let option of element[0].options) {
            datas.push({
                id: option.value,
                text: option.text
            });
        }
        return datas;
    }
}