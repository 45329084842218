import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { ApiServiceBase } from '../base/api-service-base';
import { Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';

import { AuthService } from '../authentication/auth.service';
import { DateConverterService } from '../utils/date-converter.service';
import { MessageLogOverviewModel } from '../models/message-log-overview.model';
import { MessageLogModel } from '../models/message-log.model';
import { ObserveOnMessage } from 'rxjs/internal/operators/observeOn';


const TOKEN_NAME: string = 'auth_token';
const LOGIN_USER: string = 'login_user';


@Injectable({
    providedIn: 'root'
})
export class GatewayService extends ApiServiceBase {

    constructor(private httpClient: HttpClient,
        private authService: AuthService,
        private dateConverterService: DateConverterService) {
        super(httpClient);
    }

    public sendMessage(data: any): Observable<boolean> {
        const messageLogUrl: string = '/gateway';
        return this.httpPost<any>(messageLogUrl, data)
            .pipe(
                map(response => {
                    if (response.status == 200) {
                        return true;
                    }
                    return false;
                })
            );
    }
}