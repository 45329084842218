import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, ViewRef } from '@angular/core';
import { Router } from '@angular/router';
import { TtyMessageLogView } from '../tty-message-log.view'
import { MessageListTruncatePipe } from './message-list-truncate.pipe';
import { DatatableComponentBase } from '../../../../core/base/datatable-component-base';
import { datatableTtyMessageOption, tabsOption } from '../tty-message-config';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';

declare var $: any;

@Component({
    selector: 'gw-message-list',
    templateUrl: './message-list.component.html',
    styleUrls: [
        './message-list.component.css'
    ],
    providers: [
        MessageListTruncatePipe
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MessageListComponent extends DatatableComponentBase implements OnInit {

    public ttyMessageLogs$: Subject<TtyMessageLogView[]> = new Subject();
    public messageSearch: string;
    public tabs;
    public tabId: number;
    public dataTableOption: any;
    public unreadCheck: boolean = false;
    public loading = true;
    subscription: Subscription;

    private blankRegex = "^$";

    private readFlagColumn = 6;

    public cleanDatatable() {
        this.destroyDatatable();
        this.ttyMessageLogs$.next();
        this.loading = true;
    }

    constructor(
        private router: Router,
        private pipe: MessageListTruncatePipe,
        private cdf: ChangeDetectorRef
    ) {
        super();
    }

    @Input() dataReceived$ = new BehaviorSubject<TtyMessageLogView[]>(null);
    @Input() messageFilter: string;
    @Input() filterInput: string;
    @Input() pageArchived: string;
    @Input() pageInbox: string;
    @Output() itemSelectedOutput = new EventEmitter();
    @Output() itemDoubleClick = new EventEmitter();
    @Output() subjectTrig = new EventEmitter();

    private ttyMessageLogView: TtyMessageLogView[];

    ngOnInit(): void {
        this.tabs = tabsOption;
        this.messageFilter = '';
        this.filterInput = 'Filter';
        this.dataTableOption = { ...datatableTtyMessageOption };
        this.createTTYDatatableButton();
        this.subsctibeData();
        this.reloadData();
        this.setTab(1);
        this.initCustomFiltering();
        this.loading = true;
        this.regisColumnTable(this.dataTableOption);
    }

    checkValue() {
        let id;
        if (this.unreadCheck) {
            id = 2;
        } else {
            id = 1;
        }
        this.setTab(id);
        this.disableButton();
    }

    private setTab(id: number) {
        this.tabId = id;
        let searchTerm = "";
        if (this.tabId == 2) {
            searchTerm = this.blankRegex;
        } else {
            searchTerm = "";
        }
        let instance = this.getDatatableInstance();
        if (instance) {
            instance.then((dtInstance: DataTables.Api) => {
                dtInstance.columns([this.readFlagColumn]).search(searchTerm, true).draw();
            })
        }
    }

    private subsctibeData() {
        this.destroyDatatable();
        this.dataTableOption.ajax = (dataTablesParameters: any, callback) => {
            this.dataReceived$
                .subscribe(
                    (response: TtyMessageLogView[]) => {
                        if (response != null) {
                            this.ttyMessageLogView = response;
                            callback({
                                data: response
                            });
                            if (this.unreadCheck) {
                                this.setTab(2);
                            }
                            this.loading = false;
                            if (this.cdf && !(this.cdf as ViewRef).destroyed) {
                                this.cdf.detectChanges();
                            }
                        }
                    }
                );
        }
    }

    public doubleClick(item: TtyMessageLogView) {
        this.itemSelectedOutput.emit(item);
        this.itemDoubleClick.emit(item);
        this.clear();
    }

    public reload() {
        this.clear();
        this.cleanDatatable();
        this.subjectTrig.emit("reload");
    }

    private clear() {
        this.clearDataFiltering();
    }

    private sendNewMessage() {
        this.sendMessageData("sendnew", true);
    }

    private replyMessage() {
        this.sendMessageData("reply", true);
    }

    private reprocessyMessage() {
        this.sendMessageData("reprocess", true);
    }

    private resendMessage() {
        this.sendMessageData("resend", true);
    }

    private copyAndSendMessage() {
        this.sendMessageData("copy-resend", true);
    }

    private showMessageLogDetail() {
        this.sendMessageData("", false);
    }

    public archivedMessage() {
        this.sendMessageData("archived", true);
        this.cleanDatatable();
    }

    private sendMessageData(message: string, check: boolean) {
        this.getSelectedData<TtyMessageLogView>()
            .subscribe((response) => {
                if (response.length > 0) {
                    this.itemSelectedOutput.emit(response[0]);
                    if (check) {
                        this.subjectTrig.emit(message);
                    } else {
                        this.itemDoubleClick.emit(response[0]);
                    }
                    this.clear();
                }
            });
    }

    private clearDataFiltering() {
        this.messageSearch = '';
        this.messageFilter = '';
        this.filterInput = 'Filter';
    }

    private createTTYDatatableButton() {
        let replyClass, resendClass;
        if (this.pageInbox) {
            replyClass = 'btn-outline-primary btn-sm';
            resendClass = 'hidden';
        } else {
            replyClass = 'hidden';
            resendClass = 'btn-outline-primary btn-sm';
        }
        let datatableButtons = [
            {
                extend: 'copyHtml5',
                text: 'Copy',
                titleAttr: 'Copy to clipboard',
                className: 'btn-outline-primary btn-sm',
                exportOptions: {
                    columns: [0, 1, 2, 3, 7, 5],
                },
                action: function (e, dt, node, config) {
                    $.fn.dataTable.ext.buttons.copyHtml5.action.call(this, e, dt, node, config);
                    $('#datatables_buttons_info').fadeOut(function () {
                        $(this).remove();
                    });
                }
            },
            {
                extend: 'print',
                text: 'Print',
                titleAttr: 'Print Table',
                className: 'btn-outline-primary btn-sm',
                exportOptions: {
                    stripHtml: false,
                    columns: ':visible'
                }
            },
            {
                extend: 'pdfHtml5',
                text: 'PDF',
                titleAttr: 'Generate PDF',
                className: 'btn-outline-primary btn-sm',
                pageSize: 'LEGAL',
                exportOptions: {
                    columns: [0, 1, 2, 3, 7, 5]
                },
                customize: function (doc) {
                    var tblBody = doc.content[1].table.body;
                    $('#dt-message-box').find('tr').each(function (ix, row) {
                        var index = ix;
                        var rowElt = row;
                        $(row).find('td').each(function (ind, elt) {
                            if (tblBody[index][0].text == 'Highest') {
                                delete tblBody[index][0].style;
                                tblBody[index][0].fillColor = '#E60000';
                            }
                            else if (tblBody[index][0].text == 'Urgent') {
                                delete tblBody[index][0].style;
                                tblBody[index][0].fillColor = '#FF8C00';
                            }
                            else if (tblBody[index][0].text == 'Normal') {
                                delete tblBody[index][0].style;
                                tblBody[index][0].fillColor = '#6b8e23';
                            }
                            else if (tblBody[index][0].text == 'Deferred') {
                                delete tblBody[index][0].style;
                                tblBody[index][0].fillColor = '#1e90ff';
                            }
                            else if (tblBody[index][0].text == 'No Priority') {
                                delete tblBody[index][0].style;
                                tblBody[index][0].fillColor = '#808080';
                            }
                        });
                    });
                }
            },
            {
                extend: 'csvHtml5',
                text: 'CSV',
                titleAttr: 'Generate CSV',
                className: 'btn-outline-primary btn-sm',
                exportOptions: {
                    columns: [0, 1, 2, 3, 7, 5]
                }
            },
            {
                text: 'Reply',
                className: replyClass,
                extend: 'selected',
                action: (e, dt, node, config) => {
                    this.replyMessage();
                }
            },
            {
                text: 'Reprocess',
                className: replyClass,
                extend: 'selected',
                action: (e, dt, node, config) => {
                    this.reprocessyMessage();
                }
            },
            {
                text: 'Resend',
                className: resendClass,
                extend: 'selected',
                action: (e, dt, node, config) => {
                    this.resendMessage();
                }
            },
            {
                text: 'Copy and Send',
                className: resendClass,
                extend: 'selected',
                action: (e, dt, node, config) => {
                    this.copyAndSendMessage();
                }
            },
            {
                text: 'Detail',
                className: 'btn-outline-primary btn-sm',
                extend: 'selected',
                action: (e, dt, node, config) => {
                    this.showMessageLogDetail();
                }
            }
        ];
        this.dataTableOption.buttons = datatableButtons;
    }

    private regisColumnTable(dataTableOption: any) {
        let self = this;
        dataTableOption.columns = [
            {
                "data": "messagePriorityText",
                'render': function (data) {
                    return self.getStatusClass(data);
                }
            },
            { "data": "senderIdentification" },
            { "data": "receiverIdentification" },
            { "data": "messageSubTypeCode" },
            { "data": "messageBody" },
            { "data": "messageDateTime" },
            { "data": "readDateTime" },
            { "data": "messageBody" },
            { "data": "messageTypeCode" }
        ];

        dataTableOption.columnDefs = [
            {
                'targets': [0, 1, 2, 3, 5],
                "width": "12%"
            },
            {
                'targets': [5],
                className: 'text-right'
            },
            {
                'targets': [6, 7, 8],
                "visible": false
            },
            {
                'targets': [1, 2, 3, 5, 6],
                'render': function (data, type, row) {
                    return self.setReadDateTime(data, row, false);
                }
            },
            {
                'targets': [4, 6],
                'render': function (data, type, row) {
                    return self.setReadDateTime(data, row, true);
                }
            }
        ];

        dataTableOption.rowCallback = (row: Node, data: any | Object, index: number) => {
            const doubleClickEvent: string = "dblclick";
            const registerEventAtTag: string = "td";
            $(registerEventAtTag, row).unbind(doubleClickEvent);
            $(registerEventAtTag, row).bind(doubleClickEvent, () => {
                this.doubleClick(data)
            });

            return row;
        }
    }

    private initCustomFiltering() {
        let self = this;
        $.fn.dataTable.ext.search.push(
            function (settings, data, dataIndex) {
                var columnNameDict = { "Message Date Time": 5, "Sender": 1, "Priority": 0, "Text": 7, "Message Type": 3, "Receiver": 2 };
                self.messageSearch = self.messageFilter.toLowerCase();
                if (self.messageSearch) {
                    if (self.filterInput == 'Filter') {
                        if (data[7].toLowerCase().indexOf(self.messageSearch) >= 0 || data[3].toLowerCase().indexOf(self.messageSearch) >= 0 || data[0].toLowerCase().indexOf(self.messageSearch) >= 0) {
                            return true;
                        }
                        return false;
                    }

                    let dataIndex = columnNameDict[self.filterInput];
                    if (data[dataIndex].toLowerCase().indexOf(self.messageSearch) >= 0) {
                        return true;
                    }
                    return false;
                }
                return true;
            }
        );
    }

    private enableButton() {
        var table = $('#dt-message-box').DataTable();
        table.buttons([4, 5, 6, 7]).enable();
    }

    private disableButton() {
        var table = $('#dt-message-box').DataTable();
        table.buttons([4, 5, 6, 7]).enable(false);
    }

    public getStatusClass(status: string) {
        const highest: string = "Highest";
        const urgent: string = "Urgent";
        const normal: string = "Normal";
        const deferred: string = "Deferred";

        if (status == highest) {
            return '<span  class="badge badge-pill priority-high"> ' + status + '</span>'
        } else if (status == urgent) {
            return '<span  class="badge badge-pill priority-urgent"> ' + status + '</span>';
        } else if (status == normal) {
            return '<span  class="badge badge-pill priority-normal"> ' + status + '</span>';
        } else if (status == deferred) {
            return '<span  class="badge badge-pill priority-defer"> ' + status + '</span>';
        } else {
            return '<span  class="badge badge-pill priority-other"> ' + status + '</span>';
        }
    }

    private setReadDateTime(data: any, row: any, check: boolean) {
        let self = this;
        if (row.readDateTime) {
            return '<span style="font-weight: 400">' + data + '</span>';
        } else {
            if (check) {
                return '<span style="font-weight: 700">' + self.pipe.transform(data) + '</span>';
            } else {
                return '<span style="font-weight: 700">' + data + '</span>';
            }
        }
    }
}