import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiServiceBase } from '../base/api-service-base';
import { AuthService } from '../authentication/auth.service';
import { OrganisationModel } from '../models/organisation-model';
import { SendReceiveListModel } from '../models/send-receive-list.model';
import { MessagingDefinitionModel } from '../models/messaging-definition.model';
import { SendReceiveModel } from '../models/send-receive.model';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService extends ApiServiceBase {

    constructor(private httpClient: HttpClient,
        private authService: AuthService) {
        super(httpClient);
    }
    
    public getAllActiveOrganisation(): Observable<OrganisationModel[]> {
        const serviceUrl: string = '/Organisation/Active';
        let token = this.authService.getToken();
        return this.httpGet<OrganisationModel[]>(serviceUrl, null, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getMessageSendReceiveByOrgId(organisationId: string): Observable<SendReceiveListModel[]> {
        const serviceUrl: string = '/Organisation/' + organisationId + '/MessageSendReceives';
        let token = this.authService.getToken();
        return this.httpGet<SendReceiveListModel[]>(serviceUrl, null, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getMessagingDefinitionByOrganisation(organisationId: string): Observable<MessagingDefinitionModel[]> {

        const serviceUrl: string = '/Organisation/' + organisationId + '/MessagingDefinitions';
        let token = this.authService.getToken();
        return this.httpGet<MessagingDefinitionModel[]>(serviceUrl, null, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getMessageSendReceive(organisationId: string, messageSendReceiveId: string): Observable<SendReceiveModel> {
        
        const serviceUrl: string = '/Organisation/' + organisationId + '/MessageSendReceives/' + messageSendReceiveId;
        let token = this.authService.getToken();
        return this.httpGet<SendReceiveModel>(serviceUrl, null, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public addMessageSendReceive(model: SendReceiveModel): Observable<boolean> {

        const serviceUrl = "/Organisation/" + model.organisationId + "/MessageSendReceives";
        if (!model) {
            return null;
        }
        let token = this.authService.getToken();
        return this.httpPost(serviceUrl, model, token)
            .pipe(
                map(response => {
                    if (response.status == 200) {
                        return true;
                    }
                    return false;
                })
            );
    }

    public editMessageSendReceive(model: SendReceiveModel): Observable<boolean> {
        if (!model) {
            return null;
        }
        
        const serviceUrl = "/Organisation/" + model.organisationId + "/MessageSendReceives/" + model.id;
        let token = this.authService.getToken();
        return this.httpPut(serviceUrl, model, token)
            .pipe(
                map(response => {
                    if (response.status == 200) {
                        return true;
                    }
                    return false;
                })
            );
    }

    public deleteMessageSendReceive(organisationId: string, id: string): Observable<boolean> {
        
        const serviceUrl = "/Organisation/" + organisationId + "/MessageSendReceives/" + id;
        if (!id) {
            return null;
        }
        let token = this.authService.getToken();
        return this.httpDelete(serviceUrl, token)
            .pipe(
                map(response => {
                    if (response.status == 200) {
                        return true;
                    }
                    return false;
                })
            );
    }

    public getOrganisationById(organisationId: string): Observable<OrganisationModel[]> {
        const serviceUrl: string = '/Organisation/' + organisationId;
        let token = this.authService.getToken();
        return this.httpGet<OrganisationModel[]>(serviceUrl, null, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}