import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiServiceBase } from '../base/api-service-base';
import { AuthService } from '../authentication/auth.service';
import { MessageLogOverviewModel } from '../../core/models/message-log-overview.model';
import { StringHelperService } from '../../core/utils/string-helper.service';
import { DateConverterService } from '../../core/utils/date-converter.service';
import { MessageLogModel } from '../../core/models/message-log.model';
import { MessageLogOverviewSummaryModel } from '../models/message-log-overview-summary.model';
import { MessagingDefinitionCountModel } from '../models/messaging-definition-count.model';
import { TotalMessageCountModel } from '../models/total-message-count.model';
import { MessagseLogRuleModel } from '../models/message-log-rule.model';

@Injectable({
    providedIn: 'root'
})
export class MessageLogService extends ApiServiceBase {

    constructor(private httpClient: HttpClient,
        private authService: AuthService,
        private stringHelper: StringHelperService,
        private dateConvert: DateConverterService) {
        super(httpClient);
    }

    public getMessageLogOverviews(messageTypeCode: string,
        messageSubtypeCode: string,
        sender: string,
        receiver: string,
        startMessageDateTime: string,
        endMessageDateTime: string,
        message: string,
        organisationId: string,
        statusCode: string,
        schemaTypeCode: string,
        responseSize: number = 1000): Observable<MessageLogOverviewModel[]> {
        const serviceUrl = "/MessageLogs";

        let token = this.authService.getToken();

        let params = new HttpParams()
            .set("MessageTypeCode", this.stringHelper.undefinedToEmpty(messageTypeCode))
            .set("MessageSubTypeCode", this.stringHelper.undefinedToEmpty(messageSubtypeCode))
            .set("Sender", this.stringHelper.undefinedToEmpty(sender))
            .set("Receiver", this.stringHelper.undefinedToEmpty(receiver))
            .set("StartMessageDateTime", this.stringHelper.amPmToDateString(startMessageDateTime))
            .set("EndMessageDateTime", this.stringHelper.amPmToDateString(endMessageDateTime))
            .set("Message", this.stringHelper.undefinedToEmpty(message))
            .set("OrganisationId", this.stringHelper.undefinedToEmpty(organisationId))
            .set("MessageStatusCode", this.stringHelper.undefinedToEmpty(statusCode))
            .set("SchemaTypeCode", this.stringHelper.undefinedToEmpty(schemaTypeCode))
            .set("ResponseSize", this.stringHelper.undefinedToEmpty(responseSize.toString()));

        return this.httpGet<MessageLogOverviewModel[]>(serviceUrl, params, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public GetMessagePairLogDetail(messageLogIndexId: string, indexName: string): Observable<MessageLogModel[]> {

        const serviceUrl = "/MessageLogs/";
        let token = this.authService.getToken();

        let params = new HttpParams()
            .set("indexName", indexName);

        return this.httpGet<MessageLogModel[]>(serviceUrl + messageLogIndexId + "/pair", params, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    private createDateParam(messageDateString: string) {
        if (!messageDateString || messageDateString == "") {
            return null;
        }
        let messageDateTime = new Date(messageDateString);
        return this.dateConvert.dateTimeToUTCString(messageDateTime);
    }

    public getMessageLogDetail(messageLogIndexId: string): Observable<MessageLogModel> {
        const messageLogUrl: string = '/MessageLogs/' + messageLogIndexId;
        return this.httpGet<MessageLogModel>(messageLogUrl, null)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getMessageLogOverviewSummary(organisationId: string,
        second: string,
        lastRefresh: string): Observable<MessageLogOverviewSummaryModel[]> {

        const serviceUrl = "/MessageLogs/summary";

        let token = this.authService.getToken();

        let params = new HttpParams()
            .set("OrganisationId", this.stringHelper.undefinedToEmpty(organisationId))
            .set("Second", second)
            .set("LastRefresh", lastRefresh)

        return this.httpGet<MessageLogOverviewSummaryModel[]>(serviceUrl, params, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public getMessagingDefinitionCount(organisationId: string): Observable<MessagingDefinitionCountModel[]> {
        const serviceUrl = "/MessagingDefinitions/messages-count";

        let token = this.authService.getToken();

        let params = new HttpParams()
            .set("organisationId", this.stringHelper.undefinedToEmpty(organisationId))

        return this.httpGet<MessagingDefinitionCountModel[]>(serviceUrl, params, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );

    }

    public getMessageTypeCount(organisationId: string, year: string, month: string) {
        const serviceUrl = "/statistics/messages-count";

        let token = this.authService.getToken();

        let params = new HttpParams()
            .set("organisationId", this.stringHelper.undefinedToEmpty(organisationId))
            .set("year", year)
            .set("month", month)

        return this.httpGet<TotalMessageCountModel[]>(serviceUrl, params, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            )

    }

    public updateRead(id: any): Observable<boolean> {
        const messageLogUrl: string = '/MessageLogs/' + id + '/read';
        return this.httpPut(messageLogUrl, null)
            .pipe(
                map(response => {
                    if (response.status == 200) {
                        return true;
                    }
                    return false;
                })
            );
    }

    public updateArchived(id: any): Observable<boolean> {
        const messageLogUrl: string = '/MessageLogs/' + id + '/archived';
        return this.httpPut(messageLogUrl, null)
            .pipe(
                map(response => {
                    if (response.status == 200) {
                        return true;
                    }
                    return false;
                })
            );
    }

    public getMessageLogRules(sendReceiveId: string): Observable<MessagseLogRuleModel[]> {
        const messageLogUrl: string = "/MessageLogs/MessageSendReceives/" + sendReceiveId + "/Rules";
        let token = this.authService.getToken();
        return this.httpGet<MessagseLogRuleModel[]>(messageLogUrl, null, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public addMessageLogRule(model: MessagseLogRuleModel) {
        let authKey = this.authService.getToken();
        const messageLogUrl: string = "/MessageLogs/Rules";
        if (!model) {
            return null;
        }
        return this.httpPost(messageLogUrl, model)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }

    public deleteMessageLogRule(id: string) {

        const messageLogUrl: string = "/MessageLogs/Rules/" + id;
        let token = this.authService.getToken();
        if (!id) {
            return null;
        }

        return this.httpDelete(messageLogUrl, token)
            .pipe(
                map(response => {
                    return response.body;
                })
            );
    }
}
