import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'gw-search-message',
    templateUrl: './search-message.component.html'
})

export class SearchMessageComponent {

    @Input() pageInbox: string;

    private searchText: string;
    @Output() SearchTextChange = new EventEmitter();
    @Input()
    get SearchText() {
        return this.searchText
    }

    set SearchText(val) {
        if (this.searchText !== val) {
            this.searchText = val;
            this.SearchTextChange.emit(this.SearchText);
        }
    }

    private filterColumn: string;
    @Output() FilterColumnChange = new EventEmitter();
    @Input()
    get FilterColumn() {
        return this.filterColumn
    }

    set FilterColumn(val) {
        if (this.filterColumn !== val) {
            this.filterColumn = val;
            this.FilterColumnChange.emit(this.FilterColumn);
        }
    }

    public onSelectFilter(selectedFilter: string) {
        this.FilterColumn = selectedFilter;
    }

    onSearchTextChanged(value) {
        this.SearchText = value;
    }
}