import { Component, OnInit } from "@angular/core";

@Component({
    selector: "",
    template: "<router-outlet></router-outlet>"
})

export class TTYMessageComponent  implements OnInit{
    ngOnInit() {

    }
}