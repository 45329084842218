import { NgModule } from '@angular/core';
import { PanelsModule } from 'src/app/shared/panels/panels.module';
import { PopoverModule } from 'ngx-bootstrap';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { OrganisationSelectorComponent } from './organisation-selector.component';
import { Select2Module } from '../../ui/forms/inputs/oops-select2/select2.module';
import { OrganisationSelectorPipe } from './organisation-selector.pipe';

@NgModule({
    imports: [
        CommonModule,
        DataTablesModule,
        PanelsModule,
        PopoverModule,
        Select2Module
    ],
    declarations: [
        OrganisationSelectorComponent,
        OrganisationSelectorPipe
    ],
    exports: [
        OrganisationSelectorComponent
    ],
    providers: [
        OrganisationSelectorPipe
    ]
})
export class OrganisationSelectorModule { }