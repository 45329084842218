import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SearchMessageComponent } from './shared/search-message/search-message.component'
import { MessageListComponent } from './shared/message-list/message-list.component'
import { MessageListTruncatePipe } from './shared/message-list/message-list-truncate.pipe'
import { MessageDetailComponent } from './shared/message-detail/message-detail.component'
import { SendMessageComponent } from './shared/send-message/send-message.component'
import { InboxComponent } from './inbox/inbox.component';
import { TtyMapperService } from './shared/tty-mapper.service';
import { DataTablesModule } from 'angular-datatables';
import { InboxArchivedComponent } from './inbox-archived/inbox-archived.component';
import { PanelsModule } from '../../shared/panels/panels.module';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { NgxPrintModule } from 'ngx-print';
import { OutboxComponent } from './outbox/outbox.component';
import { OutboxArchivedComponent } from './outbox-archived/outbox-archived.component';
import { MenuListComponent } from './menu-list/menu-list.component';
import { Select2Module } from '../../shared/ui/forms/inputs/oops-select2/select2.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: 'inbox', component: MenuListComponent,
                data:
                {
                    breadcrumbs: [
                        {
                            name: 'TTY message',
                            path: '/main/tty-message/inbox'
                        }
                    ]
                }
            }
        ]),
        BsDropdownModule.forRoot(),
        Ng2SearchPipeModule,
        FormsModule,
        ReactiveFormsModule,
        DataTablesModule,
        PanelsModule,
        ClipboardModule,
        NgxPrintModule,
        Select2Module,
        NgbModule
    ],
    declarations: [
        SearchMessageComponent,
        MessageListComponent,
        MessageListTruncatePipe,
        MessageDetailComponent,
        SendMessageComponent,
        InboxComponent,
        InboxArchivedComponent,
        OutboxComponent,
        OutboxArchivedComponent,
        MenuListComponent
    ],
    providers: [
        TtyMapperService
    ]
})
export class TTYMessageModule { }
