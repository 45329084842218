import { Component, OnChanges, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MessageLogService } from '../../../../core/services/message-logs.service'
import { TtyMapperService } from '../tty-mapper.service';
import { MessageLogModel } from '../../../../core/models/message-log.model';
import { TtyMessageLogDetailView } from '../tty-message-log-detail.view';
import { PdfExportService, CsvExportTTYService } from '../../../../core/utils/exports'
import { ToastrService } from 'ngx-toastr';
import { getRouterState } from 'src/app/store/router';
import { Store } from '@ngrx/store';
import { TtyMessageLogView } from '../tty-message-log.view';

@Component({
    selector: 'gw-message-detail',
    templateUrl: 'message-detail.component.html',
    styleUrls: ['./message-detail.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDetailComponent implements OnChanges {

    public dataDetail: TtyMessageLogDetailView;
    public itemSelectedIdReceived: string;
    public dataArchived = false;
    private priority;

    @Input() itemSelectedObj: TtyMessageLogView;
    @Output() closeDetailFlag = new EventEmitter();
    @Output() subjectTrig = new EventEmitter();
    @Input() pageInbox: string;
    @Input() dataAllPaging: TtyMessageLogView[];
    @Input() selectPage: number;

    public previousPage: number;
    public collectionSize: number;
    public page: number = 1;
    public maxSize: number = 5;
    public showPaging: boolean = false;

    constructor(private messageLogService: MessageLogService,
        private ttyMapperService: TtyMapperService,
        private cdr: ChangeDetectorRef,
        private pdfExport: PdfExportService,
        private csvTTYExport: CsvExportTTYService,
        private toastr: ToastrService,
        private store: Store<any>) {
    }

    ngOnChanges(change: any) {
        this.cdr.detectChanges();
        this.refresh();
    }

    public refresh() {
        if (this.itemSelectedObj) {
            this.getMessageDetail(this.itemSelectedObj.id, this.itemSelectedObj.indexName);
            if (this.dataAllPaging.length > 0) {
                this.showPaging = true;
                this.collectionSize = this.dataAllPaging.length;
                this.loadPagination(this.selectPage);
            }
            this.closeDetailFlag.emit(false);
            this.setBreadCrumb('Detail', '');
        }
    }

    private getMessageDetail(messageLogIndexId: string, indexName: string) {
        this.messageLogService.GetMessagePairLogDetail(messageLogIndexId, indexName)
            .subscribe(
                (response: MessageLogModel[]) => {
                    if (response) {
                        this.dataDetail = this.ttyMapperService.toTtyMessageLogDetailViews(response[0]);
                        this.setArchived();
                        this.cdr.detectChanges();
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

    private setArchived() {
        if (this.dataDetail.archiveDateTime) {
            this.dataArchived = true;
        } else {
            this.dataArchived = false;
        }
    }

    private copy() {
        this.toastr.info('Copy message body to clipboard', 'Copy to clipboard', { timeOut: 2000 })
    }

    private closeDetail() {
        this.closeDetailFlag.emit(true);
        this.removeBreadCrumb();
    }

    private sendNewMessage() {
        this.subjectTrig.emit("sendnew");
        this.removeBreadCrumb();
    }

    private reply() {
        this.subjectTrig.emit("reply");
        this.removeBreadCrumb();
    }

    public archived() {
        this.subjectTrig.emit("archived");
        this.removeBreadCrumb();
    }

    private resendMessage() {
        this.subjectTrig.emit("resend");
        this.removeBreadCrumb();
    }

    private reprocessMessage() {
        this.subjectTrig.emit("reprocess");
        this.removeBreadCrumb();
    }

    private copyAndSendMessage() {
        this.subjectTrig.emit("copy-resend");
        this.removeBreadCrumb();
    }

    private pdfExportFile() {
        let color = this.ttyMapperService.priorityMapperColor(this.dataDetail.messagePriorityText);
        let message = {
            content:
                [
                    {
                        text: [
                            'Priority : ',
                            { text: this.dataDetail.messagePriorityText, style: 'priority' }
                        ]
                    },
                    'Sender : ' + this.dataDetail.senderIdentification,
                    'Receiver : ' + this.dataDetail.receiverIdentification,
                    'Date : ' + this.dataDetail.messageDateTime,
                    'Type : ' + this.dataDetail.messageSubTypeCode,
                    'Text : ' + this.dataDetail.messageBody
                ],
            styles: {
                priority: {
                    background: color
                }
            }
        };
        this.pdfExport.download("tty-message-detail-pdf", message);
    }

    private csvExportFile() {
        let messageBodyFormat = this.formatMessage(this.dataDetail.messageBody, "");
        let messageObj =
        {
            "Priority": this.dataDetail.messagePriorityText,
            "Sender": this.dataDetail.senderIdentification,
            "Receiver": this.dataDetail.receiverIdentification,
            "Type": this.dataDetail.messageSubTypeCode,
            "Date": this.dataDetail.messageDateTime,
            "Text": messageBodyFormat
        }
        this.csvTTYExport.download("tty-message-detail-csv", messageObj);
    }

    private formatMessage(message: string, sign: string): string {
        const br: string = "<br/>";
        let splitStrings: string[] = message.split(br)
        let result: string = "";
        for (let i = 0; i < splitStrings.length; i++) {
            if (splitStrings[i].length > 0) {
                result += splitStrings[i] + sign;
            }
        }
        return result;
    }

    private setBreadCrumb(name, path) {
        let a = this.store.select(getRouterState);
        a.subscribe(b => {
            if (b.state.data.breadcrumbs[0].name == 'TTY message') {
                b.state.data.breadcrumbs.push({ name: name, path: path });
            }
        })
    }

    private removeBreadCrumb() {
        let a = this.store.select(getRouterState);
        a.subscribe(b => {
            if (b.state.data.breadcrumbs[0].name == 'TTY message') {
                b.state.data.breadcrumbs.pop();
            }
        })
    }

    public selectPagination(pageSelect: number) {
        if (!this.dataAllPaging) {
            return;
        }

        if (!pageSelect) {
            return;
        }
        if (pageSelect !== this.previousPage) {
            this.previousPage = pageSelect;
            this.loadDatatoTable(pageSelect);
        }
    }

    public loadPagination(pageSelect: number) {

        if (!this.dataAllPaging) {
            return;
        }

        if (!pageSelect) {
            return;
        }

        this.page = pageSelect;
    }

    private loadDatatoTable(pageSelect: number) {
        this.dataDetail = null;
        if (this.dataAllPaging) {
            this.getMessageDetail(this.dataAllPaging[pageSelect - 1].id, this.dataAllPaging[pageSelect - 1].indexName);
            this.closeDetailFlag.emit(false);
        }
    }
}