import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { getRouterState } from 'src/app/store/router';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'smart-page-breadcrumb',
  templateUrl: './page-breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageBreadcrumbComponent implements OnInit, OnDestroy {

  date = Date.now();

  vm$ = this.store.select(getRouterState);

  private interval;

  constructor(
    private store: Store<any>,
    private ref: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.ref.markForCheck();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
