import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gw-auth',
    templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
        
    }
}