import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiServiceBase } from '../base/api-service-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel } from '../models/user-model';

const TOKEN_NAME: string = 'auth_token';
const LOGIN_USER: string = 'login_user';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends ApiServiceBase {
    constructor(private httpClient: HttpClient) {
        super(httpClient);
    }

    public login(userName: string, password: string): Observable<boolean> {
        const authUrl: string = '/individual/auth';
        let authKey = this.generateAuthKey(userName, password);

        this.clearStorage();
        return this.httpGet<UserModel>(authUrl, null, authKey)
            .pipe(
                map(response => {
                    if (response.status == 200) {
                        localStorage.setItem(LOGIN_USER, JSON.stringify(response.body));
                        localStorage.setItem(TOKEN_NAME, authKey);
                        return true;
                    }
                    return false;
                })
            );
    }

    public logout(): void {
        this.clearStorage();
    }

    private generateAuthKey(username: string, password: string): string {
        let base64Creds = btoa(username + ":" + password);
        return base64Creds;
    }

    public isLogin(): boolean {
        return localStorage.getItem(TOKEN_NAME) != null;
    }
    private clearStorage() {
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem(LOGIN_USER);
    }
    public getUserInformation(): UserModel {
        var userJson = localStorage.getItem(LOGIN_USER);
        let user: UserModel = JSON.parse(userJson);
        return user;
    }
    public getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
    }
}